import { Observable } from "rxjs";
import { ITurbineRepository } from "src/app/domain/repositories/turbine-repository.interface";
import { ITurbineService } from "./turbine.service.interface";
import { Injectable } from "@angular/core";
import { Turbine } from "src/app/domain/models/turbine";

@Injectable({
    providedIn: 'root'
  })

export class TurbineService implements ITurbineService {
    
    constructor(
        private turbineRepository: ITurbineRepository
    ){ }


    getAll(farmId: string): Observable<Turbine[]> {
        return this.turbineRepository.getAll(farmId);
    }
    getTurbineDetail(farmId: string, turbineId: string): Observable<Turbine> {
        return this.turbineRepository.getTurbineDetail(farmId,turbineId);
    }
}